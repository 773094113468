<template>
  <header id="h-header">
    <div class="wrapper grid">
      <section id="logo">
        <img id="desktop-logo" src="img/logos/logo_white-min.png" alt="">
        <img id="mobile-logo" src="img/logos/logo_min-min.png" alt="">
      </section>
      <hamburger id="hamburger" :stroke='4' :gap='5' color='#fff' :open.sync='mobileOpen'></hamburger>
      <nav class="home-nav" id="desktop">
        <ul>
          <li @click="!jumping ? j('h-hero') : false">Home</li>
          <li @click="!jumping ? j('h-about') : false">Como funciona</li>
          <li @click="!jumping ? j('h-platform'):false">Plataforma</li>
          <li @click="!jumping ? j('h-services') : false">Serviços</li>
          <li @click="!jumping ? j('h-contact') : false">Contato</li>
        </ul>
        <h-social></h-social>
      </nav>
      <nav :class="{ __open: mobileOpen }" id="mobile">
        <ul>
          <li @click="!jumping ? j('h-hero') : false">Home</li>
          <li @click="!jumping ? j('h-about') : false">Como funciona</li>
          <li @click="!jumping ? j('h-platform'):false">Plataforma</li>
          <li @click="!jumping ? j('h-services') : false">Serviços</li>
          <li @click="!jumping ? j('h-contact') : false">Contato</li>
        </ul>
        <!-- <h&#45;social></h&#45;social> -->
      </nav>
    </div>
  </header>
</template>

<script>
import hSocial from './h-social.vue'
import hamburger from 'vue-hamburger'
import jump from 'jump.js'
import 'vue-hamburger/index.css'
export default {
  name: 'h-header',
  data () {
    return {
      mobileOpen: false,
      jumping: false
    }
  },
  methods: {
    j (target) {
      this.jumping = true
      this.mobileOpen = false
      jump(`#${target}`, {
        duration: 1000,
        offset: -96,
        callback: () => {
          this.jumping = false
          this.mobileOpen = false
        }
      })
    }
  },
  components: {
    hSocial,
    hamburger
  }
}
</script>
