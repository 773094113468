<template>
  <footer class="home-footer" id="h-footer">
    <span id="bar"></span>
    <div class="wrapper">
      <img src="img/logo_full-min.png" alt="">
    </div>
  </footer>
</template>

<script>
export default {
  name: 'h-footer'
}
</script>
