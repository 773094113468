<template>
  <main id="home" class="page">
    <h-header></h-header>
    <h-hero></h-hero>
    <h-about></h-about>
    <img id="banner" src="img/backgrounds/banner-min.jpg" alt="">
    <h-platform></h-platform>
    <h-services></h-services>
    <h-contact></h-contact>
    <h-footer></h-footer>
  </main>
</template>

<script>
import hHeader from './home/h-header.vue'
import hHero from './home/h-hero'
import hAbout from './home/h-about'
import hPlatform from './home/h-platform.vue'
import hServices from './home/h-services.vue'
import hContact from './home/h-contact.vue'
import hFooter from './home/h-footer.vue'
export default {
  name: 'home',
  components: {
    hHeader,
    hHero,
    hAbout,
    hPlatform,
    hServices,
    hContact,
    hFooter
  }
}
</script>
