<template>
  <div id="app">
    <transition name="fade" mode="out-in">
      <router-view/>
    </transition>
    <div id="refresh" v-if="updateExists">
      <p>
      Nova atualização encontrada!
      </p>
      <button @click="refreshApp">
        Atualizar
      </button>
    </div>
  </div>
</template>

<script charset="utf-8">
export default {
  name: 'app',
  data () {
    return {
      loaded: false,
      refreshing: false,
      updateExists: false,
      registration: null
    }
  },
  created () {
    // This piece of code setup the pwa update system
    document.addEventListener('swUpdated', this.showRefreshUI, { once: true })
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return
      this.refreshing = true
      window.location.reload()
    })
  },
  beforeMount () {
    // This piece of code deal with routing
    if (!this.$route.matched.length) {
      const target = this.$route.path.split('/').pop()
      if (this.$router.options.routes.map(r => r.name).indexOf(target) !== -1) this.$router.push({ name: target, query: this.$route.query })
      else this.$router.push({ name: 'home' })
    }
  },
  methods: {
    showRefreshUI (e) {
      this.registration = e.detail
      this.updateExists = true
    },
    refreshApp () {
      this.updateExists = false
      if (!this.registration || !this.registration.waiting) return
      // send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage('skipWaiting')
    }
  },
  components: {
  }
}
</script>

<style lang="scss">
  @import "./scss/main.scss";
</style>
