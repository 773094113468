<template>
  <section class="home-section" id="h-hero">
    <div class="background"></div>
    <div class="wrapper">
      <h1>O CAMINHO PARA LUCRAR NAS APOSTAS ESPORTIVAS</h1>
      <button>Saiba mais</button>
      <!-- <div class="content"> -->
      <!-- </div> -->
    </div>
  </section>
</template>

<script>
export default {
  name: 'h-hero'
}
</script>
