<template>
  <div class="h-social">
    <a href="https://www.instagram.com/vmsinvestimentos/" target="_blank">
      <i-instagram id="instagram"></i-instagram>
    </a>
    <a href="https://api.whatsapp.com/send?l=pt&amp;phone=5577999320046" target="_blank">
      <i-whatsapp id="whatsapp"></i-whatsapp>
    </a>
  </div>
</template>

<script>
import iWhatsapp from '../../../public/img/icons/whatsapp-brands.svg'
import iInstagram from '../../../public/img/icons/instagram-brands.svg'
export default {
  name: 'h-social',
  components: {
    iWhatsapp,
    iInstagram
  }
}
</script>
