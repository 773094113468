<template>
  <section class="home-section" id="h-platform">
    <div class="wrapper">
      <header>
        <h2>A PLATAFORMA</h2>
        <img src="img/bet365-logo--min.png" alt="">
        <span></span>
      </header>
      <p>Se você nunca teve conta na Bet365, a recomendação é que você crie uma, pois essa casa é a que nos permite a maior variedade de jogos e mercados, resultando em um lucro maior.  Para criação da conta Bet365 é necessário: Documento de identificação, comprovante de residência e os dados de sua conta bancária. Caso você já tenha conta na Bet365, basta nos passar o seu usuário e a sua senha de acesso a conta.</p>
      <p>O lucro pode ser retirado sempre que o investidor desejar, no entanto o recomendado é que se espere chegar a um valor razoável, que ajude a obter maiores retornos, de modo que a retirada não afete o desempenho da conta. Nossos administradores estarão sempre disponíveis para orienta-los a respeito.</p>
      <!-- <div class="content"> -->
      <!-- </div> -->
    </div>
  </section>
</template>

<script>
export default {
  name: 'h-about'
}
</script>
