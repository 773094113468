<template>
  <section class="home-section" id="h-about">
    <div class="wrapper">
      <header>
        <h2>COMO FUNCIONA?</h2>
        <span></span>
      </header>
      <h4>Bem-vindo a VMS Investimentos Esportivos!</h4>
      <p>Você sabia que 97% das pessoas que apostam, perdem dinheiro e somente 3% são apostadores de sucesso? Isso ocorre pois a maioria das pessoas realizam apostas sem métodos e sem análises. Nós da VMS Investimentos Esportivos cuidamos do seu dinheiro com responsabilidade, fazendo apostas com base em análises minuciosas e informações que são de extrema importância no mundo das apostas.</p>
      <p>Com um investimento de R$ 5.000 reais, aplicamos esse dinheiro com uma gestão segura na sua própria conta da Bet365, operando em diversas modalidades esportivas tais como futebol, basquete, tênis, dentre outras. Não cobramos mensalidades. O custo que você terá, será de 50% do lucro obtido pelo trabalho que realizaremos.</p>
      <p>A movimentação do seu dinheiro estará em planilhas mostrando o retorno sobre o investimento e como está sendo aplicado. Os investidores terão acesso as planilhas a cada 7 dias para verificação dos lucros.</p>
      <!-- <div class="content"> -->
      <!-- </div> -->
    </div>
  </section>
</template>

<script>
export default {
  name: 'h-about'
}
</script>
