<template>
  <section class="home-section" id="h-contact">
    <div class="wrapper">
      <header>
        <h2>CONTATO</h2>
        <span></span>
        <h4>Ficou com alguma dúvida?</h4>
        <h4>Deixe aqui a sua mensagem  que entraremos em contato.</h4>
      </header>
      <form @submit.prevent="send">
        <input v-model="form.name" placeholder="Digite seu nome" name="name" type="text" required>
        <input v-model="form.email" placeholder="Digite seu email" name="email" type="email" required>
        <textarea v-model="form.message" placeholder="Digite sua mensagem" id="" rows="5" required></textarea>
        <button type="submit">
          <span v-if="!sending">
            Enviar
          </span>
          <hollow-dots-spinner
            v-else
            :animation-duration="1000"
            :dot-size="15"
            :dots-num="3"
            color="#fff"/>
        </button>
      </form>
      <h-social></h-social>
    </div>
  </section>
</template>

<script>
import hSocial from './h-social.vue'
import jump from 'jump.js'
import { HollowDotsSpinner } from 'epic-spinners'
export default {
  name: 'h-contact',
  data () {
    return {
      sending: false,
      form: {
        name: null,
        email: null,
        message: null
      }
    }
  },
  methods: {
    send () {
      this.sending = true
      this.$api.post('https://mail.vmsinvestimentos.com.br/api/contact', this.form).then(response => {
        this.$toasted.success('Email enviado com sucesso!', {
          theme: 'bubble',
          position: 'top-center',
          duration: 1000,
          onComplete: () => {
            this.form = {
              name: null,
              email: null,
              message: null
            }
            jump(`#h-hero`, {
              duration: 1000,
              offset: -96,
              callback: () => {
                this.sending = false
              }
            })
          }
        })
      })
    }
  },
  components: {
    hSocial,
    HollowDotsSpinner
  }
}
</script>
