<template>
  <section class="home-section" id="h-services">
    <div class="wrapper">
      <header>
        <h2>NOSSOS SERVIÇOS</h2>
        <span></span>
      </header>
      <swiper :options="swiperOption" :not-next-tick="false" ref="mySwiper">
        <swiper-slide v-for="(slide, index) in slides" :key="index">
          <div class="content">
            <img :src="`img/icons/${slide.icon}-min.png`" alt=""/>
            <h3>{{ slide.title}}</h3>
            <p>{{ slide.description }}</p>
            <!-- <div class="slideContent"> -->
            <!-- </div> -->
            <!-- <div class="slideBackground"> -->
            <!-- </div> -->
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
      <!-- <div class="content"> -->
      <!-- </div> -->
    </div>
  </section>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
  name: 'h-services',
  data () {
    return {
      slides: [
        {
          title: 'ANÁLISES',
          description: 'Trabalhamos sempre em prol do cliente, com muita dedicação e profissionalismo, com análises baseadas em informações privilegiadas e serviços de tipsters profissionais.',
          icon: 'analysis'
        },
        {
          title: 'ADMINISTRAÇÃO',
          description: 'Fornecemos uma planilha que será atualizada semanalmente com os resultados obtidos na sua conta.',
          icon: 'administration'
        },
        {
          title: 'PRAZOS',
          description: 'O prazo mínimo do investimento é de 2 meses. Após o depósito do valor inicial, poderá ser realizado saques somente após 60 dias.',
          icon: 'deadlines'
        },
        {
          title: 'CUSTOS',
          description: '50% do lucro. Você só irá pagar pelo serviço quando realizar saque para a sua conta, fazendo com que o investimento por jogo se torne maior.',
          icon: 'costs'
        },
        {
          title: 'RENTABILIDADE',
          description: 'Como se trata de um investimento de renda variável, não há como estabelecer uma porcentagem fixa. Podemos atingir meses de lucro ou não, no entanto, trabalhamos para obter no mínimo 10 unidades de lucro ao mês.',
          icon: 'retability'
        }
      ],
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 16,
        autoplay: {
          delay: 4500,
          disableOnInteraction: true
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        breakpoints: {
          970: {
            slidesPerView: 2
          },
          600: {
            slidesPerView: 1
          }
        }
      }
    }
  },
  components: {
    swiper, swiperSlide
  }
}
</script>
